import React from 'react';
import styled from 'styled-components';
import { bool, func } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SDescriptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: ${STYLES_CONSTANTS.COLORS.CREAMY};
  position: absolute;
  left: -52px;
  bottom: 0;
  cursor: pointer;
  ${MEDIA.max768`
        bottom: -52px;
        left: 0;
    `}
  ${(props) =>
    props.reversed &&
    `
        left: auto;
        right: -52px;
    `}
`;

const SArrow = styled.div`
  width: 20px;
  height: 20px;
  border-left: 3px solid ${STYLES_CONSTANTS.COLORS.WHITE};
  border-bottom: 3px solid ${STYLES_CONSTANTS.COLORS.WHITE};
  transform: rotate(45deg);
  transition: 0.3s ease-out;
  ${(props) =>
    props.reversed &&
    `
        transform: rotate(225deg);
    `}
  ${(props) =>
    props.isOpen &&
    `
        transform: rotate(225deg);
        ${MEDIA.max768`
            transform: rotate(135deg);
        `}
    `}
    ${(props) =>
    props.isOpen &&
    props.reversed &&
    `
        transform: rotate(45deg);
    `}
    ${(props) =>
    !props.reversed &&
    MEDIA.max768`
          transform: rotate(225deg);
    `}
     ${(props) =>
    props.isOpen &&
    MEDIA.max768`
          transform: rotate(135deg);
    `}
`;

export const DescriptionButton = (props) => {
  const { reversed, toggleVisibility, isOpen } = props;
  return (
    <SDescriptionButton reversed={reversed} onClick={toggleVisibility}>
      <SArrow reversed={reversed} isOpen={isOpen} />
    </SDescriptionButton>
  );
};

DescriptionButton.defaultProps = {
  reversed: false,
};

DescriptionButton.propTypes = {
  isOpen: bool.isRequired,
  toggleVisibility: func.isRequired,
  reversed: bool,
};
