import React from 'react';
import styled from 'styled-components';
import CLINICS from 'jsons/main.json';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

import { Clinic } from './Clinic';

const SClinics = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 3rem;
  ${MEDIA.max1024`
        margin-left: 0;
    `}
`;

const SEmpty = styled.h3`
  font-family: 'Abhaya Libre', serif;
  color: ${STYLES_CONSTANTS.COLORS.GREY};
`;

export const Clinics = () => {
  const areClinicsExist = Object.keys(CLINICS.clinics).length > 0;
  const clinicsToRender = CLINICS.clinics.map((clinic) => (
    <Clinic key={clinic.id} clinic={clinic} />
  ));

  return (
    <SClinics>
      {areClinicsExist ? (
        clinicsToRender
      ) : (
        <SEmpty>Niedługo lista z naszymi lokalizacjami!</SEmpty>
      )}
    </SClinics>
  );
};
