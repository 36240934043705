import React from 'react';
import styled from 'styled-components';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

import { Clinics } from './Clinics';
import { WhereWeAreText } from './WhereWeAreText';

const SWhereWeAreContainer = styled.div`
  display: flex;
  background-color: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  min-height: 50vh;
  width: 90%;
  padding: 3rem;
  ${MEDIA.max1024`
        flex-direction: column;
    `}
`;

export const WhereWeAreContainer = () => {
  return (
    <SWhereWeAreContainer>
      <WhereWeAreText />
      <Clinics />
    </SWhereWeAreContainer>
  );
};
