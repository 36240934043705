import React from 'react';
import styled from 'styled-components';
import { SectionWrapper } from 'components/section-wrapper';
import { SECTIONS } from 'constants/sections';

import { WhereWeAreContainer } from './components/WhereWeAreContainer';

const SSectionWrapper = styled(SectionWrapper)`
  width: 100%;
`;

export const WhereWeAreSection = () => {
  return (
    <SSectionWrapper id={SECTIONS.WHERE_WE_ARE.slice(2)}>
      <WhereWeAreContainer />
    </SSectionWrapper>
  );
};
