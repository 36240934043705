import React from 'react';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';

const SDoctorDescription = styled.div`
  width: 100%;
  height: 100%;
  padding: 2rem;
  color: ${STYLES_CONSTANTS.COLORS.WHITE};
  background-color: ${STYLES_CONSTANTS.COLORS.CREAMY_TRANSPARENT};
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  transition: 0.3s ease-out;
  ${(props) =>
    props.isOpen &&
    `
        opacity: 1;
    `}
`;

export const DoctorDescription = (props) => {
  const { description, isOpen } = props;
  return <SDoctorDescription isOpen={isOpen}>{description}</SDoctorDescription>;
};

DoctorDescription.propTypes = {
  description: string.isRequired,
  isOpen: bool.isRequired,
};
