import React from 'react';
import styled from 'styled-components';
import { SectionWrapper } from 'components/section-wrapper';
import { SECTIONS } from 'constants/sections';
import MEDIA from 'styles/media';

import { MainBackground } from './components/MainBackground';
import { MainText } from './components/MainText';

const SSectionWrapper = styled(SectionWrapper)`
  position: relative;
  display: flex;
  margin-top: 15rem;
  justify-content: space-between;
  ${MEDIA.max1024`
    margin-top: 8rem;
  `}
`;

export const MainSection = () => {
  return (
    <SSectionWrapper id={SECTIONS.HOME.slice(1)}>
      <MainText />
      <MainBackground />
    </SSectionWrapper>
  );
};
