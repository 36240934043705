import React from 'react';
import faqData from 'jsons/faq.json';

import { DoctorsSection } from './doctors-section';
import { FaqSection } from './faq-section';
import { GallerySection } from './gallery-section';
import { Lexicon } from './lexicon';
import { MainSection } from './main-section';
import { NewsletterSection } from './newsletter';
import { OfferSection } from './offer-section';
import { PreparationSection } from './preparation-section';
import { RecommendationsSection } from './recommendations-section';
import { WhereWeAreSection } from './where-we-are-section';

export const HomeView = () => {
  return (
    <>
      <MainSection />
      <RecommendationsSection />
      <OfferSection />
      <DoctorsSection />
      <GallerySection />
      <WhereWeAreSection />
      <Lexicon />
      <FaqSection faqData={faqData} />
      <NewsletterSection />
      <PreparationSection />
    </>
  );
};
