import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components/section-title';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SOurDoctors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SParagraph = styled.div`
  color: ${STYLES_CONSTANTS.COLORS.GREY};
  text-align: center;
  width: 60%;
  margin-bottom: 3rem;
  ${MEDIA.max1200`
        width: 80%;
    `}
`;

export const OurDoctorsText = () => {
  return (
    <SOurDoctors>
      <SectionTitle title="Nasi Lekarze Medycyny Estetycznej" />
      <SParagraph>
        Zadbają o Twój komfort oraz zaproponują najlepsze i sprawdzone
        rozwiązania dla Twojej skóry.
      </SParagraph>
    </SOurDoctors>
  );
};
