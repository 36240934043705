import React from 'react';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import { Z_INDEX } from 'styles/constants/zIndexes';
import MEDIA from 'styles/media';

const SParagraph = styled.p`
  font-weight: bold;
  font-size: 2.3rem;
  font-family: 'Abhaya Libre', serif;
  position: absolute;
  bottom: 60px;
  left: -100px;
  z-index: ${Z_INDEX.LOW};
  transition: 0.3s ease-out;
  max-width: 60vw;
  padding: 0 1rem;
  background-color: ${COLORS.CREAMY};
  color: ${COLORS.WHITE};
  ${MEDIA.max768`
        left: 70px;
        bottom: -52px;
    `}
  ${MEDIA.max420`
        font-size: 1.8rem;
    `}
    ${(props) =>
    props.reversed &&
    `
        left: auto;
        right: -125px;
    `}
    ${(props) =>
    props.isOpen &&
    !props.reversed &&
    `
        left: -200px;
    `}
    ${(props) =>
    props.isOpen &&
    props.reversed &&
    `
        left: auto;
        right: -240px;
    `}
`;

export const FullName = (props) => {
  const { fullName, reversed, isOpen } = props;
  return (
    <SParagraph reversed={reversed} isOpen={isOpen}>
      {fullName}
    </SParagraph>
  );
};

FullName.defaultProps = {
  reversed: false,
};

FullName.propTypes = {
  fullName: string.isRequired,
  isOpen: bool.isRequired,
  reversed: bool,
};
