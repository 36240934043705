import React, { useState } from 'react';
import { node } from 'prop-types';

import { AppModal } from './components/AppModal';

export const WithModalContext = React.createContext({
  toggleModal: () => {},
  closeModal: () => {},
});

const _WithModal = (props) => {
  const { children } = props;
  const [modalIsVisible, setModalVisibility] = useState(false);
  const [content, setContent] = useState(null);

  const closeModal = () => {
    setModalVisibility(false);
  };

  const toggleModal = (modalContent) => {
    setModalVisibility(true);
    setContent(modalContent);
  };

  return (
    <WithModalContext.Provider
      value={{
        toggleModal,
        closeModal,
      }}
    >
      <AppModal closeModal={closeModal} modalIsVisible={modalIsVisible}>
        {content}
      </AppModal>
      {children}
    </WithModalContext.Provider>
  );
};

_WithModal.defaultProps = {
  children: '',
};

_WithModal.propTypes = {
  children: node,
};

export const WithModal = _WithModal;
