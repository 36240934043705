import React from 'react';
import styled from 'styled-components';
import { ContactToClipboard } from 'modules/footer/components/ContactToClipboard';
import { shape, string } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import Marker from 'svgs/icons/marker.svg';
import { gtagEvent } from 'utils/utils';

const SClinic = styled.div`
  display: flex;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

const STextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const STitle = styled.h3`
  font-family: 'Abhaya Libre', serif;
  font-size: 1.6rem;
`;

const SAddress = styled.p`
  margin-bottom: 0.1rem;
  font-size: 1.1rem;
  color: ${STYLES_CONSTANTS.COLORS.GREY};
`;

const SContactToClipboard = styled(ContactToClipboard)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 240px;
`;

const BookAVisit = styled.a`
  font-size: 1rem;
  width: 240px;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 52px;
  display: flex;
  box-shadow: ${STYLES_CONSTANTS.BOX_SHADOWS.BLACK_BUTTON};
  background: ${STYLES_CONSTANTS.COLORS.CREAMY};
  color: ${STYLES_CONSTANTS.COLORS.WHITE};
  text-decoration: none;
`;

const SMarker = styled(Marker)`
  width: 29px;
  min-width: 29px;
  height: 49px;
  margin-right: 20px;
`;

export const Clinic = (props) => {
  const { clinic } = props;
  return (
    <SClinic>
      <SMarker />
      <STextWrapper>
        <STitle>{clinic.title}</STitle>
        <SAddress>{clinic.address}</SAddress>
        <SAddress>{clinic.addressSecond}</SAddress>
        <SAddress>{clinic.addressThird}</SAddress>
        <SContactToClipboard textId="661 719 698" />
        <BookAVisit
          href={clinic.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={gtagEvent}
        >
          ZAREZERWUJ WIZYTĘ
        </BookAVisit>
      </STextWrapper>
    </SClinic>
  );
};

Clinic.propTypes = {
  clinic: shape({
    title: string,
    address: string,
    id: string,
    url: string,
  }).isRequired,
};
