import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components/section-title';
import MEDIA from 'styles/media';

import { Preparations } from './components/Preparations';

const SWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-bottom: 50px;

  ${MEDIA.max768`
        width: 90%;
    `};
`;

export const PreparationSection = () => {
  return (
    <SWrapper>
      <SectionTitle title="Współpracujemy z Następującymi Markami" />
      <Preparations />
    </SWrapper>
  );
};
