import React from 'react';
import styled from 'styled-components';
import { LinkButton } from 'components/link-button';
import { SectionTitle } from 'components/section-title';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SLexicon = styled.div`
  width: 80%;
  max-width: 88.125rem;
  height: 16.25rem;
  margin: 1rem auto 6rem;
  border: 1px solid ${COLORS.LIGHT_GREY};
  display: flex;
  ${MEDIA.max1024`
    flex-direction: column-reverse;
    height: unset;
  `}
`;

const SWrapper = styled.div`
  padding: 2rem 4rem 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  ${MEDIA.max1024`
    align-items: center;
    padding: 2rem 4rem;
  `};
`;

const STitle = styled(SectionTitle)`
  text-align: right;
  ${MEDIA.max1024`
    text-align: center;
  `};
`;

const SImg = styled(GatsbyImage)`
  width: 60%;
  height: 100%;
  ${MEDIA.max1024`
    min-height: 7.1875rem;
  `};
`;

export const Lexicon = () => {
  const image = useStaticQuery(graphql`
    query {
      lexicon: file(relativePath: { eq: "lexicon.png" }) {
        childImageSharp {
          gatsbyImageData(width: 694, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <SLexicon>
      <SImg
        image={image.lexicon.childImageSharp.gatsbyImageData}
        alt="leksykon medycyny estetycznej ideale wola warszawa"
      />
      <SWrapper>
        <STitle title="Leksykon Medycyny Estetycznej" />
        <LinkButton text="sprawdź" to="/leksykon" />
      </SWrapper>
    </SLexicon>
  );
};
