import React from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { graphql, useStaticQuery } from 'gatsby';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SDesktopImage = styled(NonStretchedImage)`
  display: block;
  background-color: ${STYLES_CONSTANTS.COLORS.BEIGE};
  max-width: 890px;
  ${MEDIA.max1024`
        display: none;
    `}
`;

const SMobileImage = styled(NonStretchedImage)`
  display: none;
  max-width: 438px;
  ${MEDIA.max1024`
        display: block;
    `}
`;

export const MainBackground = () => {
  const data = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "main.png" }) {
        childImageSharp {
          gatsbyImageData(width: 890, quality: 100, formats: [WEBP])
        }
      }
      mobile: file(relativePath: { eq: "main-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(width: 438, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <>
      <SDesktopImage
        childImageSharp={data.desktop.childImageSharp}
        alt="ideale medycyna estetyczna warszawa wola aesthetic medicine"
      />
      <SMobileImage
        childImageSharp={data.mobile.childImageSharp}
        alt="ideale medycyna estetyczna warszawa wola aesthetic medicine"
      />
    </>
  );
};
