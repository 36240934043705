import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import MEDIA from 'styles/media';

import { Preparation } from './Preparartion';

const SPreparations = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  padding-bottom: 30px;

  ${MEDIA.max1600`
        justify-content: space-around;
    `}

  ${MEDIA.max768`
      display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
      justify-content: space-around;
  `}
`;

export const Preparations = () => {
  const preparationsData = useStaticQuery(graphql`
    query {
      croma: file(relativePath: { eq: "croma.png" }) {
        childImageSharp {
          gatsbyImageData(width: 219, quality: 100, formats: [WEBP])
        }
      }
      neauvia: file(relativePath: { eq: "neauvia.png" }) {
        childImageSharp {
          gatsbyImageData(width: 245, quality: 100, formats: [WEBP])
        }
      }
      teoxane: file(relativePath: { eq: "teoxane.png" }) {
        childImageSharp {
          gatsbyImageData(width: 220, quality: 100, formats: [WEBP])
        }
      }
      hyalual: file(relativePath: { eq: "hyalual.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170, quality: 100, formats: [WEBP])
        }
      }
      ibsaderma: file(relativePath: { eq: "logo-ibsaderma.png" }) {
        childImageSharp {
          gatsbyImageData(width: 268, quality: 100, formats: [WEBP])
        }
      }
      galderma: file(relativePath: { eq: "companies/Galderma_Logo.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 260, quality: 100, formats: [WEBP])
        }
      }
      vivacy: file(relativePath: { eq: "companies/Vivacy.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 260, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  const imageDescriptions = {
    croma: 'croma ideale medycyna estetyczna warszawa wola',
    neauvia: 'neauvia ideale medycyna estetyczna warszawa wola',
    teoxane: 'teoxane ideale medycyna estetyczna warszawa wola',
    hyalual: 'hyalual ideale medycyna estetyczna warszawa wola',
    ibsaderma: 'ibsaderma ideale medycyna estetyczna warszawa wola',
    galderma: 'galderma ideale medycyna estetyczna warszawa wola',
    vivacy: 'vivacy ideale medycyna estetyczna warszawa wola',
  };

  const preparations = Object.entries(preparationsData).map(
    ([key, preparation], index) => (
      <Preparation
        key={index}
        childImageSharp={preparation.childImageSharp}
        alt={imageDescriptions[key]}
      />
    )
  );

  return <SPreparations>{preparations}</SPreparations>;
};
