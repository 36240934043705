import React from 'react';
import styled from 'styled-components';
import { SectionWrapper } from 'components/section-wrapper';
import { SECTIONS } from 'constants/sections';
import { WithModal } from 'modules/with-modal';

import { GalleryCarousel } from './components/GalleryCarousel';

const SSectionWrapper = styled(SectionWrapper)`
  margin-bottom: 7rem;
`;

export const GallerySection = () => {
  return (
    <SSectionWrapper id={SECTIONS.GALLERY.slice(2)}>
      <WithModal>
        <GalleryCarousel />
      </WithModal>
    </SSectionWrapper>
  );
};
