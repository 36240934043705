import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { bool, func, node } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import { Z_INDEX } from 'styles/constants/zIndexes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

Modal.defaultStyles.overlay.backgroundColor = STYLES_CONSTANTS.COLORS.OVERLAY;
Modal.defaultStyles.overlay.zIndex = Z_INDEX.MODAL;

const SModal = styled(Modal)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  z-index: ${Z_INDEX.HIGHEST};
  border: 5px solid ${STYLES_CONSTANTS.COLORS.WHITE};
  background-color: ${STYLES_CONSTANTS.COLORS.WHITE};
  max-width: 90vw;
  min-width: 240px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const _Modal = (props) => {
  const { modalIsVisible, closeModal, children } = props;
  return (
    <SModal
      isOpen={modalIsVisible}
      style={customStyles}
      contentLabel="Modal"
      ariaHideApp={false}
      onRequestClose={closeModal}
    >
      {children}
    </SModal>
  );
};

_Modal.defaultProps = {
  children: '',
};

_Modal.propTypes = {
  modalIsVisible: bool.isRequired,
  closeModal: func.isRequired,
  children: node,
};

export const AppModal = _Modal;
