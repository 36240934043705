import React, { useState } from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { bool, object, oneOfType, string } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

import { DescriptionButton } from './DescriptionButton';
import { DoctorDescription } from './DoctorDescription';
import { FullName } from './FullName';

const SDoctorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SDoctorWrapper = styled.div`
  background-color: ${STYLES_CONSTANTS.COLORS.LIGHT_GREY};
  width: 27vw;
  height: 45vh;
  min-height: 500px;
  margin: 1rem 1rem 5rem;
  display: flex;
  justify-content: flex-end;
  position: relative;
  ${MEDIA.max1200`
        width: 35vw;
        height: 45vh;
        margin-left: 8rem;
        margin-bottom: 3rem;
        
        ${(props) =>
          props.reversed &&
          `
            margin-left: auto;
            margin-right: 8rem;
        `}
    `}
  ${MEDIA.max768`
        min-width: 70vw;
        margin: 1rem 0 3rem;
    `}
    ${(props) =>
    props.reversed &&
    `
        justify-content: flex-start;
    `}
`;

const SDoctorImage = styled(NonStretchedImage)`
  max-width: 348px;
  position: absolute;
  bottom: 0;
  ${(props) =>
    props.reversed &&
    `
        transform: none;
    `}
`;

export const Doctor = (props) => {
  const [isOpen, setVisibility] = useState(false);
  const { childImageSharp, reversed, description, fullName, alt } = props;

  const toggleVisibility = () => {
    setVisibility(!isOpen);
  };

  return (
    <SDoctorContainer>
      <SDoctorWrapper reversed={reversed}>
        <SDoctorImage
          childImageSharp={childImageSharp}
          reversed={reversed}
          alt={alt}
        />
        <FullName fullName={fullName} reversed={reversed} isOpen={isOpen} />
        <DoctorDescription description={description} isOpen={isOpen} />
        <DescriptionButton
          reversed={reversed}
          isOpen={isOpen}
          toggleVisibility={toggleVisibility}
        />
      </SDoctorWrapper>
    </SDoctorContainer>
  );
};

Doctor.defaultProps = {
  reversed: false,
};

Doctor.propTypes = {
  childImageSharp: oneOfType([object]).isRequired,
  fullName: string.isRequired,
  description: string.isRequired,
  reversed: bool,
  alt: string,
};
