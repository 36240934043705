import React from 'react';
import { SectionWrapper } from 'components/section-wrapper';
import { SECTIONS } from 'constants/sections';

import { Doctors } from './components/Doctors';
import { OurDoctorsText } from './components/OurDoctorsText';

export const DoctorsSection = () => {
  return (
    <SectionWrapper id={SECTIONS.DOCTORS.slice(2)}>
      <OurDoctorsText />
      <Doctors />
    </SectionWrapper>
  );
};
