import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import MEDIA from 'styles/media';

import { GalleryButton } from './GalleryButton';
import { Photo } from './Photo';

const SCarouselWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const SSliderWrapper = styled.div`
  width: 100%;
`;

const SPrevButton = styled(GalleryButton)`
  position: absolute;
  bottom: 0;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
  ${MEDIA.max420`
        top: auto;
        left: 50px;
        bottom: -120px;
    `}
`;

const SNextButton = styled(GalleryButton)`
  position: absolute;
  bottom: 0;
  right: -50px;
  top: 50%;
  transform: translateY(-50%);
  ${MEDIA.max420`
        top: auto;
        left: auto;
        right: 50px;
        bottom: -120px;
    `}
`;

export const GalleryCarousel = () => {
  const images = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(png)/" }
          relativeDirectory: { eq: "gallery" }
          name: { regex: "/(large)/" }
        }
      ) {
        edges {
          node {
            id
            name
            relativePath
            childImageSharp {
              gatsbyImageData(width: 600, quality: 100, formats: [WEBP])
            }
          }
        }
      }
    }
  `);

  const getImages = (type) => {
    return images.allFile.edges
      .filter((img) => img.node.name.includes(type))
      .map((img) => ({
        id: img.node.id,
        name: img.node.name.slice(0, Math.max(0, img.node.name.indexOf('_'))),
        originalName: img.node.name,
        childImageSharp: img.node.childImageSharp,
        imageNumber: Number(img.node.name.replace(/\D/g, '')),
      }))
      .sort((a, b) => a.imageNumber - b.imageNumber);
  };

  const largeImages = getImages('large');
  const slider = useRef();
  const [slidesCount, setSlidesCount] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(
    Math.ceil(largeImages.length / slidesCount)
  );

  const changePage = (slides) => {
    setSlidesCount(slides);
    setPageCount(Math.ceil(largeImages.length / slides));
  };

  const onResize = () => {
    if (window.innerWidth < 640) {
      changePage(1);
    } else if (window.innerWidth < 1024) {
      changePage(2);
    } else if (window.innerWidth < 1200) {
      changePage(3);
    } else if (window.innerWidth < 1600) {
      changePage(4);
    } else {
      changePage(5);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
    onResize();
    return () => window.removeEventListener('resize', onResize);
  });

  const prevPage = () => {
    slider.current.slickPrev();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else {
      setCurrentPage(pageCount);
    }
  };

  const nextPage = () => {
    slider.current.slickNext();
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    } else {
      setCurrentPage(1);
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesCount,
    slidesToScroll: slidesCount,
    autoplay: true,
  };

  const generateAltText = (name) => {
    const parts = name.split('&');
    if (parts.length > 1) {
      return parts[1].trim().replace(/[_-]+/g, ' '); // Remove underscores/hyphens and trim spaces
    }
    return ''; // Return an empty string if no alt text is specified
  };

  const imagesToRender = largeImages.map((image, index) => (
    <Photo
      key={image.id}
      id={index + 1}
      smallImage={image.childImageSharp}
      name={image.name}
      largeImages={largeImages}
      alt={generateAltText(image.originalName)}
    />
  ));

  return (
    <SCarouselWrapper>
      <SSliderWrapper>
        <Slider ref={slider} {...settings}>
          {imagesToRender}
        </Slider>
      </SSliderWrapper>
      <SPrevButton onClick={prevPage} />
      <SNextButton isRight onClick={nextPage} />
    </SCarouselWrapper>
  );
};
