import React from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { STYLES_CONSTANTS } from 'styles/constants/main';

const SGalleryButton = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 1rem;
`;

const SArrow = styled.div`
  width: 20px;
  height: 20px;
  border-left: 3px solid ${STYLES_CONSTANTS.COLORS.CREAMY};
  border-bottom: 3px solid ${STYLES_CONSTANTS.COLORS.CREAMY};
  transform: rotate(45deg);
  ${(props) =>
    props.isRight &&
    `
          transform: rotate(225deg);
    `}
`;

export const GalleryButton = (props) => {
  const { isRight, onClick, className } = props;
  return (
    <SGalleryButton className={className} onClick={onClick}>
      <SArrow isRight={isRight} />
    </SGalleryButton>
  );
};

GalleryButton.defaultProps = {
  isRight: false,
};

GalleryButton.propTypes = {
  className: string.isRequired,
  onClick: func.isRequired,
  isRight: bool,
};
