import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import MEDIA from 'styles/media';

import { Doctor } from './Doctor';

const SDoctorsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SDoctors = styled.div`
  display: flex;
  ${MEDIA.max1200`
    flex-direction: column;
  `}
`;

export const Doctors = () => {
  const doctors = useStaticQuery(graphql`
    query {
      anna: file(relativePath: { eq: "Anna.png" }) {
        childImageSharp {
          gatsbyImageData(width: 345, quality: 100, formats: [WEBP])
        }
      }
      karola: file(relativePath: { eq: "karola.png" }) {
        childImageSharp {
          gatsbyImageData(width: 420, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <SDoctorsContainer>
      <SDoctors>
        <Doctor
          childImageSharp={doctors.anna.childImageSharp}
          fullName="Anna Sadowska"
          description='Lek. Anna Sadowska jest absolwentką I Wydziału Lekarskiego Warszawskiego Uniwersytetu Medycznego. Jest członkiem Polskiego Towarzystwa Medycyny Estetycznej i Anti-Aging. Jest również autorką i współautorką publikacji m.in. w czasopiśmie Dermatologia Estetyczna z zakresu dermatologii. Już podczas studiów była członkiem koła przy Katedrze i Klinice Dermatologicznej Warszawskiego Uniwersytetu Medycznego. Za swoją pracę została nagrodzona podczas Ogólnopolskiej Konferencji Naukowej „Interdyscyplinarne aspekty chorób skóry i błon śluzowych". Obecnie pracuje i odbywa szkolenie specjalizacyjne w Oddziale Alergologii w jednym z warszawskich szpitali. Anna Sadowska odbyła liczne szkolenia i warsztaty z zakresu medycyny estetycznej m.in.: zastosowanie kwasu hialuronowego, nici liftingujących, biostymulatorów oraz toksyny botulinowej. Stale podnosi swoje kwalifikacje uczestnicząc w kongresach i szkoleniach. W zabiegach medycyny estetycznej najbardziej ceni sobie profilaktykę przeciwstarzeniową i uzyskiwanie naturalnych, nieprzerysowanych efektów.'
          alt="anna sadowska lekarz madycyny estetycznej ideale medycyna estetyczna wola warszawa"
        />
        <Doctor
          childImageSharp={doctors.karola.childImageSharp}
          fullName="Anna Pakulska"
          alt="anna pakulska lekarz madycyny estetycznej ideale medycyna estetyczna wola warszawa"
          description="Lek. dent. Anna Wach jest absolwentką kierunku lekarsko-dentystycznego Śląskiego Uniwersytetu Medycznego oraz studiów podyplomowych Medycyna estetyczna dla lekarzy GWSH. Stale podnosi swoje umiejętności, uczestnicząc w różnych kursach i szkoleniach. Bardzo ceni możliwości, jakie daje medycyna estetyczna w dążeniu do upragnionego wyglądu i chce pomóc Pacjentom w jego uzyskaniu. Dużą wagę przykłada do profilaktyki przeciwstarzeniowej. Przeprowadza zabiegi m.in. przy użyciu toksyny botulinowej, kwasu hialuronowego, stymulatorów tkankowych oraz laserów medycznych. Na co dzień pracuje także jako stomatolog. "
          reversed
        />
      </SDoctors>
    </SDoctorsContainer>
  );
};
