import React from 'react';
import styled from 'styled-components';
import { bool, func, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SLabel = styled.label`
  padding-left: 1rem;
  color: ${COLORS.GREY};
  font-size: 12px;
`;

export const Checkbox = ({ id, name, value, onChange, label, required }) => {
  return (
    <div>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        required={required}
        onChange={onChange}
      />
      <SLabel htmlFor={id}>{label}</SLabel>
    </div>
  );
};

Checkbox.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
  name: string.isRequired,
  className: string,
  required: bool,
};
