import React from 'react';
import { HomeView } from 'views/home';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { Layout } from 'layouts';

const IndexPage = () => (
  <Layout>
    <HomeView />
  </Layout>
);

export default IndexPage;

export const Head = () => (
  <SEO tags={TAGS.home}>
    <meta
      name="facebook-domain-verification"
      content="br5qyd4jqdqoqb1fjcqzszrxk01dhj"
    />
  </SEO>
);
