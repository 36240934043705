import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { object, string } from 'prop-types';

const SPreparation = styled.div`
  margin: 30px 10px;
`;

export const Preparation = ({ childImageSharp, alt }) => (
  <SPreparation>
    <GatsbyImage image={childImageSharp.gatsbyImageData} alt={alt} />
  </SPreparation>
);

Preparation.propTypes = {
  childImageSharp: object.isRequired,
  alt: string,
};
