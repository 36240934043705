import React, { useContext } from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { WithModalContext } from 'modules/with-modal';
import { array, number, object, oneOfType, string } from 'prop-types';
import { BOX_SHADOWS } from 'styles/constants/boxShadows';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import { Z_INDEX } from 'styles/constants/zIndexes';
import MEDIA from 'styles/media';
import Loupe from 'svgs/icons/loupe.svg';

const SPhoto = styled(NonStretchedImage)`
  width: 240px;
  height: 240px;
  box-shadow: ${BOX_SHADOWS.BLACK_LOW};
  position: relative;
  &:after {
    transition: 0.3s ease-out;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${STYLES_CONSTANTS.COLORS.CREAMY_TRANSPARENT_V2};
    opacity: 0;
  }
`;

const SWrapper = styled.div`
  ${(props) =>
    !props.isSecond &&
    `
         margin-top: 5rem;
    `}
  ${MEDIA.max1600`
         margin-top: 0;
    `}
`;

const SPhotoWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;

  &:hover svg {
    opacity: 1;
  }

  &:hover ${SPhoto} {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: ${STYLES_CONSTANTS.COLORS.CREAMY_TRANSPARENT_V2};
      opacity: 1;
    }
  }
`;

const SLargePhotoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

const SPhotoLarge = styled(GatsbyImage)`
  width: auto;
  margin: 0;
  object-fit: contain;
  max-height: 95vh;
`;

const SCloseModalButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  &:after {
    z-index: ${Z_INDEX.LOW};
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: ${STYLES_CONSTANTS.COLORS.BLACK};
    transform: rotate(45deg);
    top: 5px;
    left: -2px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: ${STYLES_CONSTANTS.COLORS.BLACK};
    transform: rotate(135deg);
    top: 5px;
    left: -2px;
  }
`;

const SLoupe = styled(Loupe)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s ease-out;
  opacity: 0;
  z-index: ${Z_INDEX.MEDIUM};
`;

export const Photo = (props) => {
  const { smallImage, id, name, largeImages, alt } = props;
  const modalFunctions = useContext(WithModalContext);
  const { toggleModal, closeModal } = modalFunctions;

  const showLargerPhoto = () => {
    const largeImage = largeImages.find((item) => item.name === name);
    toggleModal(
      <SLargePhotoWrapper>
        <SPhotoLarge
          image={largeImage.childImageSharp.gatsbyImageData}
          alt={alt}
        />
        <SCloseModalButton onClick={closeModal} />
      </SLargePhotoWrapper>
    );
  };

  const isSecond = id % 2 === 0;

  return (
    <SWrapper isSecond={isSecond}>
      <SPhotoWrapper onClick={showLargerPhoto}>
        <SPhoto childImageSharp={smallImage} alt={alt} />
        <SLoupe className="loupe" />
      </SPhotoWrapper>
    </SWrapper>
  );
};

Photo.propTypes = {
  smallImage: oneOfType([object]).isRequired,
  largeImages: array.isRequired,
  name: string.isRequired,
  id: number.isRequired,
  alt: string,
};
