import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components/section-title';
import { STYLES_CONSTANTS } from 'styles/constants/main';
import MEDIA from 'styles/media';

const SWhereWeAreText = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${STYLES_CONSTANTS.COLORS.BILLIE_GREYISH};
  width: 35%;

  ${MEDIA.max1024`
       border-right: none;
    `}
`;

const SSectionTitle = styled(SectionTitle)`
  text-align: right;
  display: flex;
  flex-direction: column;

  ${MEDIA.max1024`
       text-align: left;
    `}
`;

const STextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 3rem 3rem 0 0;

  ${MEDIA.max1024`
       margin: 2rem 2rem 0 0;
       align-items: flex-start;
    `}
`;

export const WhereWeAreText = () => (
  <SWhereWeAreText>
    <STextWrapper>
      <SSectionTitle title="Gdzie jesteśmy?" titleSecond="Warszawa, Wola!" />
    </STextWrapper>
  </SWhereWeAreText>
);
